.ladder-create {
  input:disabled {
    color: rgb(82, 82, 82);
  }

  padding-bottom: 100px;
  dl {
    margin-top: 24px;
  }
  dt {
    color: #aaa;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    margin-bottom: 8px;
  }
  dd {
    position: relative;
  }
  .err {
    // position: absolute;
    // bottom: -20px;
    margin-top: 6px;
    color: #ff4953;
    font-size: 12px;
    font-style: normal;
  }
  .small-txt {
    color: #6e6e6e;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
  }
  input {
    width: 100%;
    height: 46px;
    padding: 0 16px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #232323;
    background: #141414;
  }
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    // background-color: #fff;
    opacity: 1;
    border-radius: 2px;
    cursor: pointer;
    background: url("../assets/images/date-icon.svg") center no-repeat;
    width: 50px;
    height: 50px;
    margin-right: -20px;
  }

  .ladder-create__input {
    .ladder-create__input__cover {
      width: 100%;
      display: flex;
      gap: 16px;
      dd {
        // &::after{
        //   content: '';
        //   display: block;
        //   position: absolute;
        //   right: 16px;
        //   top:-2px;
        //   // transform: translate(0%,-50%);
        //   width: 50px;
        //   height: 50px;
        //   background: url('../assets/images/date-icon.svg') center no-repeat;
        // }
      }
      input {
      }
      dd {
        width: 100%;
        .utc-box {
          color: #6e6e6e;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          margin-top: 8px;
          span {
            display: block;
            color: #6e6e6e;
            font-size: 12px;
            font-style: normal;
            line-height: normal;
          }
        }
      }
    }
  } //ladder-create__input--END--
  .ladder-create__textarea {
    dd {
      textarea {
        width: 100%;
        height: 92px;
        padding: 16px;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #232323;
        background: #141414;
        resize: none;
      }
    }
  } //ladder-create__textarea--END--
  .ladder-create__img {
    .image-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      transition: 0.3s;
      border: 1px solid transparent;
      position: relative;
      // &::before{
      //   content: 'Update';
      //   position: absolute;
      //   right: 16px;
      //   top:16px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   gap: 8px;
      //   width: 80px;
      //   height: 46px;
      //   border-radius: 4px;
      //   background:#272727;
      //   padding: 0 16px;
      //   font-size: 14px;
      // }
      .upload-label__up-date-btn {
      }
      &:hover {
        border: 1px solid #d50f92;
        border-radius: 4px;
      }
      input {
        display: none;
      }
      .upload-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 200px;
        border-radius: 4px;
        background-color: #141414;
        border: 1px solid #232323;
        cursor: pointer;
        overflow: hidden;
        transition: background-color 0.3s, border-color 0.3s;
        background-size: cover;

        &:hover {
          background-color: #fff;
          span {
            color: #000;
          }
          // border-color: #00acc1;
        }

        span {
          color: #999;
          font-size: 16px;
          text-align: center;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  } //ladder-create__img--END--

  .ladder-create__settings {
    dt {
    }
    dd {
      .ranking-table {
        width: 100%;
        overflow: auto;
        table {
          width: 100%;
          border-collapse: collapse;
          color: #ffffff;
          // background-color: #333;
          thead {
            tr {
              th {
                color: var(--text-grey, #6e6e6e);
                text-align: center;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
              }
            }
          }
        }

        th,
        td {
          padding: 16px;
          text-align: center;
          // border-bottom: 1px solid #444;
        }

        th {
          // background-color: #222;
          font-weight: bold;
        }

        .upload-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          .placeholder {
            color: #777;
            font-size: 12px;
            margin-top: 8px;
          }
          .logo-preview {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 5px;
            margin-bottom: 5px;
          }
          .upload-button {
            background-color: #555;
            color: #fff;
            padding: 5px 10px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 12px;
            transition: background-color 0.3s;
            &:hover {
              background-color: #777;
            }
            input {
              display: none;
            }
          }
        }
      }
    }
  } //ladder-create__settings--END--

  .ladder-create__missions,
  .ladder-create__EXP,
  .ladder-create__penalty-EXP {
    dd {
      .select-box {
        position: relative;
        &.daily {
          .select-box__list {
            z-index: 2;
          }
        }
        &.active {
          .select-box__title {
            &::after {
              transform: translate(0, -50%) rotate(180deg);
            }
          }
          .select-box__list {
            max-height: 114px;
            height: auto;
            opacity: 1;
          }
        }
        .select-box__title {
          width: 100%;
          height: 48px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          color: #f4f4f4;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          line-height: 100%;
          border-radius: 4px;
          border: 1px solid #232323;
          background: #141414;
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translate(0, -50%) rotate(0deg);
            width: 10px;
            height: 10px;
            background: url("../assets/images/arrow_more_down.png") center
              no-repeat;
            // background: url('../../assets/images/arrowUp.svg') center no-repeat;
            transition: 0.3s;
          }
        } //select-box__title--END--
        .select-box__list {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #232323;
          position: absolute;
          left: 0;
          top: 54px;
          overflow: auto;
          height: 0;
          opacity: 0;
          transition: 0.3s;
          background: #141414;
          z-index: 1;
          li {
            width: 100%;
            padding: 8px 16px;
            transition: 0.3s;
            cursor: pointer;
            font-size: 14px;
            text-align: left;
            &:hover {
              background: #232323;
            }
          }
        } //select-box__list--END--
      } //select-box--END--

      .daily-missions-table,
      .exp-settings-table,
      .penalty-exp-settings-table {
        width: 100%;
        // overflow: auto;
        // padding-bottom: 110px;
        table {
          width: 100%;
          min-width: auto;
          border-collapse: collapse;
          color: #ffffff;
          background-color: #141414;
          thead {
            tr {
              th {
                background-color: #141414;
                text-align: left;
                color: #6e6e6e;
                &:nth-child(1) {
                  width: 50%;
                }
                &:nth-child(2) {
                  padding: 16px 16px 16px 0;
                }
                &:last-child {
                  width: 50px;
                }
              }
            }
          }
          tbody {
            tr {
              &:nth-child(1) {
                td {
                  &:last-child {
                    display: none;
                  }
                  .delete-button {
                    opacity: 0;
                    cursor: not-allowed;
                  }
                }
              }
              td {
                &:nth-child(2) {
                  padding: 0;
                }
                &:last-child {
                  padding: 0;
                }
              }
              &:hover {
                background: #141414;
              }
            }
          }

          th,
          td {
            padding: 16px;
            text-align: center;
          }

          th {
            background-color: #222;
            font-weight: bold;
          }
          .delete-button {
            background: transparent;
            border: none;
            color: #f44336;
            cursor: pointer;
            font-size: 18px;
          }
        }
      }
      .add-button {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        justify-content: center;
        border: 1px solid #272727;
        color: white;
        padding: 8px 16px;
        border: none;
        cursor: pointer;
        font-size: 18px;
        transition: background-color 0.3s;
        height: 70px;
        background-color: #222;
        &:hover {
          background: #272727;
        }
      }
    }
  }

  .ladder-create__save-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #650fd5;
    width: 100px;
    height: 46px;
    margin-left: auto;
    margin-top: 16px;
    transition: 0.3s;
    &:hover {
      box-shadow: 0px 0px 12px 0px #650fd5 !important;
    }
  } //ladder-create__save-btn--END--
} //ladder-create--END--

@media (max-width: 1024px) {
  .ladder-create__missions,
  .ladder-create__EXP,
  .ladder-create__penalty-EXP {
    .daily-missions-table,
    .exp-settings-table,
    .penalty-exp-settings-table {
      overflow: auto;
      width: 100%;
      padding-bottom: 110px;
    }
    table {
      width: 930px !important;
    }
  }
}

@media (max-width: 768px) {
  .ladder-create {
    .ladder-create__input {
      .ladder-create__input__cover {
        flex-direction: column;
        gap: 32px;
      }
    }
  }
}
