body{
  // overflow: hidden;
}
.login {
  width: 100%;
  .login__container {
    display: flex;
    background:#030303;
    .login__error{
      color: #E14E4E;
      font-size: 12px;
      font-style: normal;
      line-height: 140%; /* 16.8px */
      margin-bottom: 8px;
      margin-top: -8px;
    }
    .video--left{
      width: 50%;
      height: 100vh;
      position:relative;
      overflow: hidden;
      &::after{
        content: "";
        position: absolute;
        z-index: 1;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #030303 0%, rgba(3, 3, 3, 0.00) 100%);
      }
      img{
        width: 488px;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
      }
      video{
        width: 180%;
        height: 100%;
        object-fit: cover;
        transform: translate(-25%,0);
      }
    }
    .login--input--box{
      width: 50%;
      height: 100vh;
      position: relative;
      .login--input--box--center{
        position: absolute;
        top:50%;
        left: 40%;
        transform: translate(-50%,-50%);
        z-index: 1;
      }
    }
    .logo_txt{
      color: #FFF;
      font-family:Poppins700;
      font-size: 30px;
      font-style: normal;
      line-height: 130%; /* 39px */
      margin-bottom: 40px;
      span{
        display: block;
        color:#A0AEC0;
        font-family: "Pretendard500";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
    }
    .login__logo {
      margin-bottom: 24px;
      width: 254px;
    }
    .login__input-box {
      .input-box__id,
      .input-box__pw {
        display: flex;
        width: 341px;
        flex-direction: column;
        p{
          color: #FFF;
          font-family: "Pretendard500";
          font-size: 14px;
          font-style: normal;
          line-height: 140%; /* 19.6px */
          margin-bottom: 8px;
        }
        &:first-child {
          margin-bottom:16px;
        }
        &:nth-child(2) {
          margin-bottom: 16px;
        }

        .input-box__id-input,
        .input-box__pw-input {
          padding: 20px;
          width: 100%;
          height: 50px;
          color: #fff;
          font-size: 13px;
          border-radius:20px;
          background: url('../assets/images/inputBg.png') transparent;
          background-size:100%;
          background-repeat: no-repeat;
          background-position: center;
          // border: none;
          // &:focus{
          //   border: 1px solid #5ac4ff;
          // }
        }
      }
      .input-box__submit {
        width: 341px;
        height: 40px;
        color: #fff;
        display: flex;
        padding: 22.81px 0px;
        justify-content: center;
        margin-top: 16px;
        align-items: center;
        border-radius: 12px;
        background:#D50F92;
        backdrop-filter: blur(60px);
        font-size: 15px;
        font-family: "pretendard500";
        transition: 0.3s;
        &:hover{
          box-shadow: 0 0 10px #D50F92;
          text-shadow: 0 0 8px #0006;
        }
      }
      .join--txt{
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Gray-Gray-400, #A0AEC0);
        text-align: center;
        font-family: "Pretendard500";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        margin-top: 20px;
        a{
          color: #FFF;
          font-family: "Pretendard700";
          font-size: 14px;
          font-style: normal;
          line-height: 140%;
          margin-left: 4px;
        }
      }//join--txt--END--

      .checkbox {
        margin-top: 16px;
        cursor: pointer;
        p{
          color: #FFF;
          font-family: Poppins500;
          font-size: 12px;
          font-style: normal;
          line-height: 150%; /* 18px */
        }
        input {
          display: none;
          
          // when user clicked on input checkbox style span
          &:checked + .box { 
            background: #D50F92;
            &:after {
              left: 36px - 16px - 1px;
            }
          }
        }
        
        .box {
          display: block;
          float: left;
          width: 36px;
          height: 20px;
          background-color: #272727;
          border-radius: 50px;
          margin-right:10px;
          transition: background-color 0.2s ease-in-out;
          position: relative;
          box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.4);

          &:after {
            content: '';
            position: absolute;
            width: 13.5px;
            height: 13.5px;
            background-color:#fff;
            border-radius: 50%;
            left:3px;
            top:3px;
            transition: left 0.2s ease-in-out;
          }
        }
      }//checkbox --END--
    }
  }
}

@media screen and (max-width: 1000px) {
  .login{
    .login__container{
      display: block;
      position: relative;
      .video--left{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
        z-index: 1;
        img{
          opacity: 0;
        }
      }
      .login--input--box{
        width: 100%;
        height: 100vh;
        .login--input--box--center{
          left: 50%;

        }
      }
    }
  } 
}

@media screen and (max-width: 768px) {
  .login {
    .login__container {
      h1 {
        font-size: 20px;
      }
      .login__input-box {
        .input-box__id,
        .input-box__pw {
          display: flex;
          // background-color: rgba(255, 255, 255, 0.1);
          width: 280px;
          &:first-child {
            margin-bottom: 8px;
          }
          img {
            width: 18px;
            height: 18px;
            margin: 0 8px;
          }
          .input-box__id-input,
          .input-box__pw-input {
            width: 100%;
            background-color: transparent;
            font-size: 13px;
          }
        }
        .input-box__submit {
          width: 280px;
          height: 40px;
          display: flex;
          padding: 22.81px 0px;
          justify-content: center;
          margin-top: 16px;
          align-items: center;
          font-size: 15px;
        }
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .login{
    .login__container{
      .logo_txt{
        font-size: 25px;
      }
    } 
  } 
}
