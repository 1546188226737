.highlight {
  background: #030303;
  width: 100%;
  .highlight--inner {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    .header--box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .main--title {
        color: #fff;
        font-family: "Pretendard700";
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .search--box {
        display: block;
        position: relative;
        input {
          border-radius: 16px;
          border: 1px solid var(--stroke-stroke, #232323);
          background: var(--section-bg, #141414);
          min-height: 40px;
          min-width: 240px;
          color: rgba(160, 174, 192, 0.5);
          font-family: Poppins400;
          font-size: 12px;
          font-style: normal;
          line-height: 150%; /* 18px */
          padding-left: 35px;
        }
        .search--btn {
          position: absolute;
          left: 10px;
          top: 12px;
          cursor: pointer;
        }
      } //search--box --END--
    } //header--box --END--
    .highlight--type--btn {
      display: flex;
      gap: 16px;
      button {
        border-radius: 8px;
        border: 1px solid #235aad;
        background: #030303;
        color: #fff;
        text-align: center;
        font-family: "Pretendard500";
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        transition: 0.3s;
        &:hover {
          background: #235aad;
          box-shadow: 0px 0px 12px 5px #235aad;
        }
        &.active {
          border-radius: 8px;
          background: #235aad;
          box-shadow: 0px 0px 12px 0px #235aad;
        }
      }
    } //highlight--type--btn--END
    .highlight--img--list--box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      // justify-content: center;
      padding-top: 60px;
      // grid-template-columns: repeat(4, 1fr);
      .item--box {
        display: flex;
        flex-direction: column;
        width: 288px;
        min-height: 208px;
        overflow: hidden;
        align-items: center;
        gap: 16px;
        button {
          margin-top: -5px;
          width: 100%;
          border-radius: 8px;
          border: 1px solid #235aad;
          background: #030303;
          color: #fff;
          text-align: center;
          font-family: "Pretendard500";
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
          transition: 0.3s;
          &:hover {
            background: #235aad;
            box-shadow: 0px 0px 12px 0px #235aad;
          }
        }
        dt {
          display: flex;
          align-items: center;
          gap: 8px;
          overflow: hidden;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: var(--radi-md, 4px);
            border: 1px solid var(--neutral-nr10, #232323);
            background: var(--section-bg, #141414);
            color: #fff;
            text-align: center;
            font-family: "Pretendard400";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            width: 40px;
            height: 40px;
          }
          p {
            width: 240px;
            height: 104.52px;
            background-color: #141414;
            border-radius: 4.154px;
            border: 0.519px solid var(--neutral-nr10, #232323);
            overflow: hidden;
            cursor: pointer;
            // display: flex;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        dd {
          border-radius: var(--radi-md, 4px);
          border: 1px solid var(--neutral-nr10, #232323);
          background: var(--background-bg, #030303);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          padding: 8px 16px;
        }
      } //item--box--END--
    } //highlight--img--list--box--END--
    .highlight--img--list--box--cover {
      .up--load--btn {
        width: 60%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--radi-md, 4px);
        background: var(--button-grey_bghover, #1a1a1a);
        color: var(--text-white, #f4f4f4);
        text-align: center;
        font-family: "Pretendard500";
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        transition: 0.3s;
        margin: 24px auto;
        &:hover {
          background: #235aad;
        }
      }
    }

    .highlight--up-load--list--box {
      display: flex;
      flex-direction: column;
      width: 1200px;
      margin: 0 auto;
      padding-top: 20px;
      gap: 32px;
      .up--load--end--btn {
        width: 60%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--radi-md, 4px);
        background: var(--button-grey_bghover, #1a1a1a);
        color: var(--text-white, #f4f4f4);
        text-align: center;
        font-family: "Pretendard500";
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        transition: 0.3s;
        margin: 24px auto;
        &:hover {
          background: #235aad;
        }
      }
      .left--content {
        width: 100%;
        dl {
          width: 100%;
          display: flex;
          gap: 16px;
          flex-direction: column;
          margin-top: 16px;
          &.img--up--load {
            margin-top: 32px;
            .preview-image {
              width: 592px;
              object-fit: cover;
              // max-height: 260px;
            }
          }
          dt {
            color: #a0aec0;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            p {
              margin: 16px 0 8px;
              width: 100%;
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: center;
              color: #a0aec0;
              text-align: center;
              font-family: "Pretendard500";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 55px;
                height: 28px;
                color: #fff;
                font-size: 12px;
                font-style: normal;
                line-height: normal;
                border-radius: 100px;
                border: 1px solid var(--4383-ff, #4383ff);
                background: rgba(0, 0, 0, 0.6);
              }
            }
          }
          dd {
            position: relative;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // gap: 16px;
            p {
              width: 100%;
            }
            input[type="datetime-local"]::-webkit-calendar-picker-indicator,
            input[type="datetime-local"]::-webkit-inner-spin-button {
              opacity: 0;
              cursor: pointer;
              // background-color: red;
            }
            input[type="datetime-local"] {
              position: relative;
              width: 100%;
              background: url("../assets/images/date_range.png") no-repeat right
                10px center;
              // width: 24px;
              height: auto;
              // background-color: #fff;
            }
            input {
              width: 100%;
              height: 40px;
              border-radius: var(--radi-md, 4px);
              border: 1px solid var(--neutral-nr10, #232323);
              background: var(--section-bg, #141414);
              padding: 16px;
              color: #fff;
              font-family: "Pretendard400";
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              &.date {
                // background: url("../assets/images/date_range.png");
                background-repeat: no-repeat;
                background-position: 98.5% 50%;
                // &::before {
                //   content: "";
                //   display: inline-block;
                //   width: 16px;
                //   height: 16px;
                //   background: url("../assets/images/date_range.png");
                //   background-position: center;
                //   background-repeat: no-repeat;
                //   background-size: cover;
                //   vertical-align: middle;
                // }
              }
            }
            .up--load--btn {
              position: absolute;
              top: 5px;
              right: 8px;
              // width: 60px;
              padding: 6px 12px;
              border-radius: var(--radi-mlg, 8px);
              background: #235aad;
              color: var(--textColor-neutral, #fff);
              font-size: 12px;
              font-style: normal;
              line-height: 18px; /* 150% */
              transition: 0.3s;
              &:hover {
                box-shadow: 0px 0px 12px 2px #235aad;
              }
            }

            &.upload-container {
              position: relative;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0 auto;
              border-radius: 8px;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              border: 2px dashed #4383ff;
              justify-content: center;
              p {
                margin-top: 16px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 8px;
                position: relative;
                top: 0px;
                &.file--up--load--txt {
                  span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: var(--radi-mlg, 8px);
                    border: 1px solid
                      var(--button-primaryOutline-stroke, #1849d6);
                    color: var(--4383-ff, #4383ff);
                    font-family: Inter600;
                    font-size: 12px;
                    font-style: normal;
                    line-height: 18px; /* 150% */
                    width: 108px;
                    height: 35px;
                  }
                }
                &.img--txt {
                  margin-bottom: 16px;
                  margin-top: 14px;
                  display: flex;
                  flex-direction: row;
                  gap: 8px;
                  color: var(--text-white, #f4f4f4);
                  font-size: 10px;
                  font-style: normal;
                  line-height: 20px; /* 200% */
                  span {
                    color: var(--text-grey, #6e6e6e);
                    font-size: 10px;
                    font-style: normal;
                    line-height: 20px; /* 200% */
                  }
                }
              }

              input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0;
                transition: 0.3s;
                background: transparent;
                border: 0;
                font-size: 0;
                opacity: 0;
                cursor: pointer;
              }
              &.active {
                input {
                  background: rgba(67, 131, 255, 0.5);
                }
                background: rgba(67, 131, 255, 0.5);
              }
              .upload-input {
                outline: none;
                transition: border-color 0.3s;

                &:hover,
                &:focus {
                  // border;
                }
              }

              .preview-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .preview-image {
                  width: 100%;
                  height: auto;
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                  transition: transform 0.3s;

                  &:hover {
                    transform: scale(1.05);
                  }
                }
              }
            }
          } //dd--END--
        }
        .img--up--load--list {
          dl {
            &:nth-child(1) {
              dd {
                height: 100% !important;
              }
              .preview-image {
                width: 590px;
                height: 260px;
              }
            }
            &:nth-child(2) {
              dd {
                height: 100% !important;
              }
              .preview-image {
                width: 768px;
                height: 500px;
              }
            }
            &:nth-child(3) {
              dd {
                height: 100% !important;
              }
              .preview-image {
                width: 320px;
                height: 400px;
              }
            }
          }
        } //img--up--load--list--END--
      } //left--content--END--
      .right--content--cover {
        width: 50%;
        .right--title {
          margin-top: 16px;
          margin-bottom: 24px;
          color: #a0aec0;
          text-align: center;
          font-family: "Pretendard500";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .right--content {
          .right--content--item {
            position: relative;
            width: 100%;
            min-height: 260px;
            background: #111;
            p {
              position: absolute;
              top: 24px;
              left: 24px;
              border-radius: 300px;
              border: 1px solid var(--4383-ff, #4383ff);
              background: rgba(0, 0, 0, 0.6);
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100px;
              height: 35px;
            }
            span {
              position: absolute;
              right: 24px;
              bottom: 24px;
              color: #a0aec0;
              font-family: "Pretendard500";
              font-size: 18px;
              font-style: normal;
              line-height: normal;
            }
            &.pc {
              img {
                max-width: 592px;
                width: 100%;
                object-fit: cover;
              }
            }
          }
        } //right--content--END--
      }
    } //highlight--up-load--list--box--END--
  }
  .wallet--list--box {
    border-radius: 16px;
    background: #141414;
    padding: 24px 40px 40px;
    width: 100%;
    overflow: auto;
  }
  table {
    min-width: 1300px;
    width: 100%;
    border-collapse: collapse;
    // margin: 25px 0;
    // font-size: 0.9em;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    thead {
      tr {
        background-color: #141414;
        color: #ffffff;
        th {
          color: #a0aec0;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          &:nth-child(1) {
            width: 100px;
          }
          &:nth-child(2) {
            width: 350px;
          }
          &:nth-child(3) {
            width: 65%;
            text-indent: -500px;
          }
        }
      }
    }
    tbody {
      tr {
        background-color: #141414;
        min-height: 34px;
        td {
          text-align: center;
          color: var(--text-white, #f4f4f4);
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          &:nth-child(3) {
            width: 65%;
            text-indent: -500px;
          }
          .cancel--btn {
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #bb2424;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #bb2424;
              text-shadow: 0 0 8px #0006;
            }
          }
          .link--btn {
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #235aad;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #235aad;
              text-shadow: 0 0 8px #0006;
            }
          }
          &:nth-child(7) {
            text-align: left;
          }
          &:nth-child(9) {
            width: 80px;
          }
          &:nth-child(10) {
            width: 80px;
          }
        }
        &:last-child {
          border-bottom: 0;
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  table th,
  table td {
    padding: 12px 8px;
    background-color: #141414;
    border-bottom: 1px solid var(--stroke-stroke, #232323);
  }

  .pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background: var(--button-grey, #272727);
      color: var(--text-white, #f4f4f4);
      text-align: center;
      font-family: Poppins500;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      margin-top: 30px;
      border: 1px solid #272727;
      transition: 0.3s;
      &:hover {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.active {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.prev,
      &.next {
        font-size: 0;
        background: url("../assets/images/arrow_next.png") #272727;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.prev {
        transform: rotate(180deg);
      }
    }
  }
}

.loading--icon {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #03030389;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
  }
} //loading--icon--END--

@media (max-width: 1280px) {
  .highlight--up-load--list--box {
    width: 100% !important;
    padding: 60px 16px 0;
  } //highlight--up-load--list--box--END--
  .highlight--img--list--box {
    width: 100% !important;
    padding: 60px 16px 0 !important;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 850px) {
  .highlight {
    .highlight--inner {
      .highlight--up-load--list--box {
        .left--content {
          dl {
            dd {
              input {
                &.date {
                  background-position: 97.5% 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .img--up--load--list {
    dl {
      &:nth-child(1) {
        dd {
          height: 100% !important;
        }
        .preview-image {
          width: 100% !important;
        }
      }
      &:nth-child(2) {
        dd {
          height: 100% !important;
        }
        .preview-image {
          width: 100% !important;
        }
      }
      &:nth-child(3) {
        dd {
          height: 100% !important;
        }
        .preview-image {
          width: 100% !important;
        }
      }
    }
  } //img--up--load--list--END--
}

@media (max-width: 650px) {
  .wallet {
    .wallet--list--box {
      padding: 10px 24px;
    }
  }
  .highlight {
    .highlight--inner {
      .highlight--up-load--list--box {
        .left--content {
          dl {
            dd {
              input {
                &.date {
                  background-position: 96.5% 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 510px) {

  .wallet {
    .wallet--inner {
      padding: 40px 18px;
      .header--box {
        .main--title {
          font-size: 30px;
        }
      }
    }
  }
  .highlight {
    .highlight--inner {
      .header--box{
        .main--title{
          flex-direction: column;
        }
      }
      .highlight--up-load--list--box {
        .left--content {
          dl {
            dd {
              input {
                &.date {
                  background-position: 95.5% 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}
