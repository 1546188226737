.user--info {
  background: #030303;
  width: 100%;
  .user--info--inner {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    .header--box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .main--title {
        color: #fff;
        font-family: "Pretendard700";
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .search--box {
        display: block;
        position: relative;
        input {
          border-radius: 16px;
          border: 1px solid var(--stroke-stroke, #232323);
          background: var(--section-bg, #141414);
          min-height: 40px;
          min-width: 240px;
          color: rgba(160, 174, 192, 0.5);
          font-family: Poppins400;
          font-size: 12px;
          font-style: normal;
          line-height: 150%; /* 18px */
          padding-left: 35px;
        }
        .search--btn {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 40px;
          height: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      } //search--box --END--
    } //header--box --END--
    .user--info--body--box {
      border-radius: 16px;
      background: #141414;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 40px;
      .btn--box {
        width: 100%;
        display: flex;
        justify-content: right;
      }
      .user--btn {
        width: 80px;
        padding: 4px 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d50f92;
        transition: 0.3s;
        &:hover {
          box-shadow: 0 0 10px #d50f92;
        }
      }
      dl {
        display: flex;
        gap: 16px;
        dt {
          width: 120px;
          color: #a0aec0;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
        }
        dd {
          color: #fff;
          text-align: center;
          font-family: "Pretendard500";
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          display: flex;
          align-items: center;
          .drop--box {
            position: relative;
            width: 265px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: start;
            color: #fff;
            font-family: Poppins500;
            font-size: 14px;
            font-style: normal;
            line-height: 100%; /* 14px */
            border-radius: 4px;
            border: 1px solid var(--stroke-stroke, #232323);
            background: var(--button-grey, #272727);
            cursor: pointer;
            .drop--view_text {
              position: relative;
              width: 100%;
              height: 100%;
              padding: 16px;
              display: flex;
              align-items: center;
              justify-content: start;
              &::after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translate(0, -50%);
                background: url("../assets/images/arrow_more_whithe.png");
                background-size: contain;
                background-position: center;
                transition: 0.3s;
              }
            }
            &.active {
              .drop--view_text {
                &::after {
                  transform: translate(0, -50%) rotate(180deg);
                }
              }
            }
            .drop--view_text {
              color: #fff;
              font-family: Poppins500;
              font-size: 14px;
              font-style: normal;
              line-height: 100%; /* 14px */
            }
            ul {
              position: absolute;
              top: 45px;
              left: 0;
              background-color: #141414;
              width: 100%;
              height: 0;
              overflow: hidden;
              border-radius: 4px;
              transition: 0.3s;
              &.active {
                height: 150px;
                overflow: auto;
              }
              li {
                text-align: left;
                padding: 16px;
                color: #fff;
                font-family: Poppins500;
                font-size: 14px;
                font-style: normal;
                line-height: 100%; /* 14px */
                transition: 0.3s;
                cursor: pointer;
                &:hover {
                  background-color: #272727;
                }
              }
              .disabled {
                opacity: 0.2;
                cursor: not-allowed;
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
          span {
            font-family: "Pretendard500";
            font-size: 14px;
            display: flex;
          }
          p {
            color: #fff;
            text-align: left;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            &:first-child {
              margin-bottom: 8px;
            }
            &::before {
              content: "";
              display: inline-block;
              width: 16px;
              height: 16px;
              background: url("../assets/images/date_range.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              vertical-align: middle;
              margin-top: -4px;
              margin-right: 6px;
            }
            &.time {
              &::before {
                background: url("../assets/images/schedule.png");
              }
            }
          }
          input {
            padding: 4px 8px;
            background-color: #272727;
            border-radius: 8px;
            font-size: 14px;
            color: #fff;
            color-scheme: dark;
          }
        }
        .block--input--box {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          flex-direction: column;
        }
      }
    } //user--info--body--box -- END --
  }

  .record--list--table {
    border-radius: 16px;
    background: #141414;
    padding: 24px 40px 40px;
    width: 100%;
    overflow: auto;
  }
  table {
    min-width: 1300px;
    width: 100%;
    border-collapse: collapse;
    // margin: 25px 0;
    // font-size: 0.9em;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    thead {
      tr {
        background-color: #141414;
        color: #ffffff;
        th {
          color: #a0aec0;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
        }
      }
    }
    tbody {
      tr {
        background-color: #141414;
        min-height: 34px;
        transition: 0.3s;
        &:hover {
          background: #262626;
        }
        td {
          text-align: center;
          color: var(--text-white, #f4f4f4);
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          img {
            margin: 0 auto;
          }
          .cancel--btn {
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #bb2424;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #bb2424;
              text-shadow: 0 0 8px #0006;
            }
          }
          .link--btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #235aad;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #235aad;
              text-shadow: 0 0 8px #0006;
            }
          }
          &:nth-child(8) {
            width: 140px;
          }
          &:nth-child(9) {
            width: 140px;
          }
          &:nth-child(10) {
            width: 140px;
          }
          &:nth-child(11) {
            width: 140px;
          }
          .txid {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            height: 25px;
            border-radius: 4px;
            margin: 0 auto;
            background: var(
              --Linear,
              linear-gradient(90deg, #f213a4 0%, #696fff 100%)
            );
          }
        }
        &:last-child {
          border-bottom: 0;
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  table th,
  table td {
    padding: 12px 8px;
    border-bottom: 1px solid var(--stroke-stroke, #232323);
  }

  .pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background: var(--button-grey, #272727);
      color: var(--text-white, #f4f4f4);
      text-align: center;
      font-family: Poppins500;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      margin-top: 30px;
      border: 1px solid #272727;
      transition: 0.3s;
      &:hover {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.active {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.prev,
      &.next {
        font-size: 0;
        background: url("../assets/images/arrow_next.png") #272727;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.prev {
        transform: rotate(180deg);
      }
      &.prev2,
      &.next2 {
        font-size: 0;
        background: url("../assets/images/arrow_next22.png") #272727;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.prev2 {
        transform: rotate(180deg);
      }
    }
  }
}

@media (max-width: 650px) {
  .user--info {
    .record--list--table {
      padding: 10px 24px;
    }
    .user--info--body--box {
      dl {
        flex-direction: column;
        &:nth-child(2) {
          dd {
            display: flex;
            flex-direction: column;
            overflow: hidden;
          }
        }
        dd {
          span {
            display: flex;
            text-align: left;
            width: 100%;
            word-break: break-all;
            align-items: center;
          }
        }
      }
    }
  }
}
@media (max-width: 510px) {
  .user--info {
    .user--info--inner {
      padding: 40px 18px;
      .header--box {
        .main--title {
          font-size: 30px;
        }
      }
    }
  }
}
