.cancel-modal__background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

  .cancel-modal__box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    z-index: 300;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background:#171717;
    /* bg_blur */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(15px);
    padding: 40px;
    .cancel-modal__title {
      width: 320px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-family: "pretendard500";
    }
    .cancel-modal__btn-box {
      display: flex;
      margin-top: 24px;
      .cancel-modal__cancel-btn {
        width: 156px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        font-family: "pretendard500";
        background-color: #272727;
        margin-right: 8px;
      }
      .cancel-modal__confirm-btn {
        color: #fff;
        width: 156px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: #BB2424;
        font-size: 14px;
        font-family: "pretendard500";
        transition: 0.3s;
        &:hover{
          box-shadow: 0 0 10px #BB2424;
          text-shadow: 0 0 8px #BB2424;
        }
      }
    }
  }
