.super-host {
  padding: 40px;
  .super-host__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .super-host__header--text {
    color: #fff;
    font-family: "Pretendard700";
    font-weight: 400;
    font-size: 36px;
    line-height: normal;
    display: inline-block;
  }
  .super-host__header--title-box {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .super-host__header--input-box {
    border-radius: 16px;
    border: 1px solid var(--stroke-stroke, #232323);
    background: var(--section-bg, #141414);
    display: flex;
    align-items: center;
    height: 40px;
    width: 240px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
    }
  }

  .super-host__header--input {
    background-color: transparent;
    font-size: 12px;
    font-family: Poppins400;
    color: rgba(160, 174, 192, 0.5);
  }

  .super-host__list--box {
    border-radius: 16px;
    background: var(--section-bg, #141414);
    width: 100%;
    overflow: auto;
    padding: 24px 40px;
    th {
      color: #a0aec0;
      text-align: center;
      font-family: Poppins400;
      font-size: 14px;
    }
    td {
      color: #fff;
      text-align: center;
      font-family: Poppins400;
      font-size: 12px;
      div {
        font: inherit;
      }
    }
  }
  .super-host__detail-btn {
    font-family: "Pretendard500";
    border-radius: 4px;
    background: #235aad;
    padding: 4px 12px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .super-host {
    .super-host__header {
      flex-direction: column;
    }
    .super-host__header--input-box {
      margin-top: 16px;
      width: 100%;
    }
    
  }
}

@media screen and (max-width: 500px) {
  .super-host{
    .super-host__header--title-box{
      flex-direction: column;
    }
  }
}
