body {
  background: #030303;
}
.game--record {
  background: #030303;
  width: 100%;
  .game--inner {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    .header--box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      transition: 0.3s;
      .game--title {
        color: #fff;
        font-family: "Pretendard700";
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .search--box {
        display: block;
        position: relative;
        input {
          border-radius: 16px;
          border: 1px solid var(--stroke-stroke, #232323);
          background: var(--section-bg, #141414);
          min-height: 40px;
          min-width: 240px;
          color: rgba(160, 174, 192, 0.5);
          font-family: Poppins400;
          font-size: 12px;
          font-style: normal;
          line-height: 150%; /* 18px */
          padding-left: 35px;
        }
        .search--btn {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 40px;
          height: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      } //search--box --END--
    } //header--box --END--
    .game--record--list--box {
      display: flex;
      width: 100%;
      min-height: 100vh;
      justify-content: space-between;
      gap: 48px;
      .game--name--list {
        flex: 1;
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: -24px;
          width: 1px;
          height: 100%;
          background-color: #191919;
        }
        .title--text {
          color: #a0aec0;
          text-align: center;
          font-family: "Pretendard500";
          font-size: 18px;
          font-style: normal;
          line-height: normal;
          margin-bottom: 12px;
        }
        ul {
          width: 100%;
          li {
            width: 100%;
            padding: 12px;
            color: #fff;
            font-family: "Pretendard400";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #1e1e1e;
            margin-bottom: 8px;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
              border-radius: 4px;
              background: var(--button-grey_hover, #343434);
            }
            &.active {
              border-radius: 4px;
              border: 1px solid var(--d-50-f-92, #d50f92);
              background: var(--button-grey_hover, #343434);
            }
          }
        }
      } //game--name--list--END--
      .user--uid--box {
        flex: 1;
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: -24px;
          width: 1px;
          height: 100%;
          background-color: #191919;
        }
        .arr {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          margin: 16px 0;
        }
        dl {
          &:nth-child(4) {
            margin-top: 24px;
          }
          &:nth-child(5) {
            margin-top: 24px;
          }
          dt {
            color: #a0aec0;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 18px;
            font-style: normal;
            line-height: normal;
            margin-bottom: 12px;
          }
          dd {
            color: #fff;
            text-align: center;
            font-family: "Pretendard400";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            border: 1px solid #232323;
            background: #141414;
            padding: 12px;
            input {
              background: transparent;
              width: 100%;
              height: 17px;
              font-family: "Pretendard400";
              font-size: 14px;
              padding-left: 12px;
            }
            input[type="datetime-local"]::-webkit-calendar-picker-indicator,
            input[type="datetime-local"]::-webkit-inner-spin-button {
              cursor: pointer;
              // background-color: red;
              background: url("../assets/images/date_range2.png");
              background-size: contain;
            }
          }
        }

        .search--btn {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-align: center;
          font-family: "Pretendard500";
          font-size: 16px;
          font-style: normal;
          line-height: normal;
          border-radius: 4px;
          background: #235aad;
          transition: 0.3s;
          margin-top: 24px;
          &:hover {
            box-shadow: 0 0 10px #235aad;
            text-shadow: 0 0 8px #235aad;
          }
        } //search--btn--END--
      } //user--uid--box--END--
      .code--view {
        flex: 2;
        text-align: left;
        position: relative;
        // overflow: hidden;
        max-height: 100vh;
        .code--view--inner {
          opacity: 1;
          opacity: 1;
          width: 100%;
          overflow: auto;
          height: 100%;
          .none--box {
            width: 100%;
            height: 100%;
            background-color: #1e1e1e;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        dl {
          width: 99%;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: -24px;
          width: 1px;
          height: 100%;
          background-color: #191919;
        }
        .view--box {
          padding: 16px;
          // border: 1px solid #fff;
          border-radius: 4px;
          margin-bottom: 12px;
          background-color: #282c34;
          dt {
            color: #c678dd;
            p {
              display: inline-block;
              color: #e06c75;
            }
          }
          dd {
            color: #e6c07b;
            p {
              display: inline-block;
              color: #61aeee;
              margin-right: 2px;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .time--utc--box {
        flex: 1.5;
        .time--item {
          display: flex;
          gap: 24px;
          margin-bottom: 12px;
          align-items: end;
          dl {
            width: 200px;
            dt {
              color: #a0aec0;
              text-align: center;
              font-family: "Pretendard500";
              font-size: 18px;
              font-style: normal;
              line-height: normal;
              padding: 12px 0;
            }
            dd {
              height: 42px;
              input {
                background: transparent;
                width: 100%;
                padding-left: 12px;
                color: #fff;
                text-align: center;
                font-family: "Pretendard400";
                font-size: 12px;
                font-style: normal;
                line-height: normal;
              }
              padding: 12px 0;
              color: #fff;
              text-align: center;
              font-family: "Pretendard400";
              font-size: 12px;
              font-style: normal;
              line-height: normal;
              border-radius: 4px;
              border: 1px solid var(--stroke-stroke, #232323);
              background: var(--section-bg, #141414);
            }
          }
          .input--btn {
            width: 70px;
            height: 100%;
            display: flex;
            button {
              width: 100%;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              text-align: center;
              font-family: "Pretendard500";
              font-size: 16px;
              font-style: normal;
              line-height: normal;
              border-radius: 4px;
              background: #235aad;
              transition: 0.3s;
              &:hover {
                box-shadow: 0 0 10px #235aad;
                text-shadow: 0 0 8px #235aad;
              }
            }
          }
        }
      } //time--utc--box--END--
    }
  }
  .mode-dropdown__select {
    position: relative;
    text-align: left !important;
    cursor: pointer;
  }
  .mode-dropdown__list {
    background: #141414;
    top: 110%;
    border-radius: 4px;
    position: absolute;
    max-height: 120px;
    overflow: auto;
    width: 100%;
    left: 0;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .mode-dropdown__list-item {
      height: 33px;
      padding: 8px;
      width: 100%;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      font-family: "Pretendard400";
      border-radius: 4px;
      text-transform: capitalize;
      &:hover {
        background: #235aad;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .game--record {
    width: 100%;
    overflow: hidden;
    .game--inner {
      width: 100%;
      overflow: auto;
      .game--inner--box {
        width: 1400px;
        .game--record--list--box {
          height: 70vh;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  // .game--record .game--inner .game--record--list--box .code--view
  .game--record {
    overflow: visible;
    .game--inner {
      width: 100%;
      height: 100%;
      overflow: visible;
      padding: 40px 18px;
      .game--inner--box {
        width: 100%;
        .game--record--list--box {
          flex-direction: column;
          .user--uid--box {
            &::after {
              display: none;
            }
          }
          .game--name--list::after {
            display: none;
          }
          .code--view {
            max-height: none;
            width: 100%;
            .code--view--inner {
              width: 100%;
              .none--box {
                min-height: 300px;
              }
              dl {
                width: 100%;
              }
            }
            &::after {
              display: none;
            }
          }
        }
        .time--utc--box {
          padding-bottom: 40px;
          .time--item {
            justify-content: space-between;
            flex-direction: column;
            position: relative;
            gap: 4px;
            background: #1e1e1e;
            padding: 18px;
            border-radius: 4px;
            dl {
              width: 100%;
              dt {
                text-align: left;
              }
              dd {
                input {
                  text-align: left;
                }
              }
            }
            .input--btn {
              position: absolute;
              right: 18opx;
              top: 12px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
