body {
  background: #030303;
}
.main {
  background: #030303;
  width: 100%;
  .main--inner {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    .header--box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      transition: 0.3s;
      .main--title {
        color: #fff;
        font-family: "Pretendard700";
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .select--box {
        display: block;
        position: relative;
        width: 300px;
        &.active {
          ul {
            height: 110px;
            opacity: 1;
          }
        }
        .select--box__title {
          border-radius: 8px;
          background: #141414;
          color: #fff;
          font-family: Poppins400;
          font-size: 12px;
          width: 100%;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: start;
          padding: 0 16px;
          border: 1px solid #232323;
          cursor: pointer;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translate(0%, -50%);
            width: 14px;
            height: 14px;
            background: url("../assets/images/arrow_more_whithe.png") center
              no-repeat;
            background-size: cover;
          }
        } //select--box__title--END--
        ul {
          position: absolute;
          top: 40px;
          left: 0;
          width: 100%;
          border: 1px solid #232323;
          background: #141414;
          border-radius: 8px;
          overflow: auto;
          opacity: 0;
          height: 0;
          transition: 0.3s;
          li {
            width: 100%;
            white-space: nowrap;
            font-size: 14px;
            display: flex;
            align-items: center;
            height: 36px;
            padding: 16px;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
              background: #232323;
            }
          }
        }
      } //select--box--END--
      .search--box {
        display: block;
        position: relative;
        input {
          border-radius: 16px;
          border: 1px solid var(--stroke-stroke, #232323);
          background: var(--section-bg, #141414);
          min-height: 40px;
          min-width: 240px;
          color: rgba(160, 174, 192, 0.5);
          font-family: Poppins400;
          font-size: 12px;
          font-style: normal;
          line-height: 150%; /* 18px */
          padding-left: 35px;
        }
        .search--btn {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 40px;
          height: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      } //search--box --END--
    } //header--box --END--
  }
  .tournament--list--box {
    border-radius: 16px;
    background: #141414;
    padding: 24px 40px 40px;
    width: 100%;
    overflow: auto;
    .cancel--btn {
      border-radius: 4px;
      background: #bb2424;
      font-size: 14px;
      padding: 0 12px;
      height: 26px;
      &.active {
        background: #202020;
        color: #474747;
        cursor: not-allowed;
      }
    }

    .link--btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 25px;
      color: #fff;
      text-align: center;
      font-family: "Pretendard500";
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      border-radius: 4px;
      background: #235aad;
      transition: 0.3s;
      margin: 0 auto;
      &:hover {
        box-shadow: 0 0 10px #235aad;
        text-shadow: 0 0 8px #0006;
      }
    }
  }
  table {
    min-width: 1300px;
    width: 100%;
    border-collapse: collapse;
    // margin: 25px 0;
    // font-size: 0.9em;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    thead {
      tr {
        background-color: #141414;
        color: #ffffff;
        th {
          color: #a0aec0;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          min-width: 46px;
          white-space: nowrap;
          &:nth-child(1),&:nth-child(2){
            width: 260px;
          }
          &:nth-child(3){
            width: 450px;
          }
          &:nth-child(4){
            width: 400px;
          }
        }
      }
    }
    tbody {
      tr {
        background-color: #141414;
        min-height: 34px;
        transition: 0.3s;
        // cursor: pointer;
        &:hover {
          background: #262626;
        }
        td {
          text-align: center;
          color: var(--text-white, #f4f4f4);
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          &:nth-child(1),&:nth-child(2){
            white-space:nowrap;
          }
          .delete--btn {
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #bb2424;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #bb2424;
              text-shadow: 0 0 8px #0006;
            }
          }
          .detail--btn {
            width: 70px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #235aad;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #235aad;
              text-shadow: 0 0 8px #0006;
            }
          }
          &:nth-child(7) {
            // text-align: left;
          }
          &:nth-child(8) {
            width: 80px;
            text-align: center;
            img {
              margin: 0 auto;
            }
          }
          &:nth-child(9) {
            width: 80px;
          }
          &:nth-child(10) {
            width: 80px;
          }
        }
        &:last-child {
          border-bottom: 0;
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  table th,
  table td {
    padding: 12px 8px;
    border-bottom: 1px solid var(--stroke-stroke, #232323);
  }

  .pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background: var(--button-grey, #272727);
      color: var(--text-white, #f4f4f4);
      text-align: center;
      font-family: Poppins500;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      margin-top: 30px;
      border: 1px solid #272727;
      transition: 0.3s;
      &:hover {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.active {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.prev,
      &.next {
        font-size: 0;
        background: url("../assets/images/arrow_next.png") #272727;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.prev {
        transform: rotate(180deg);
      }
      &.prev2,
      &.next2 {
        font-size: 0;
        background: url("../assets/images/arrow_next22.png") #272727;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.prev2 {
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .main {
    .tournament--list--box {
      padding: 10px 24px;
    }
  }
  .header--box {
    flex-direction: column;
    gap: 16px;
    .select--box {
      width: 100% !important;
    }
    .search--box {
      input {
        min-width: 100% !important;
      }
    }
  }
}

@media (max-width: 510px) {
  .main {
    .main--inner {
      padding: 40px 18px;
      .header--box {
        .main--title {
          font-size: 30px;
        }
      }
    }
  }
}
