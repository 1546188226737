.copy-btn {
  width: 40px;
  height: 30px;
  width: 15ox;
  height: 15px;
  font-size: 0;
  background: url('../assets/images/shareIcon.png');
  background-position:center;
  background-repeat: no-repeat;
  background-size: contain;
}
