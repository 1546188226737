.wallet {
  background: #030303;
  width: 100%;
  .wallet--inner {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    .header--box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .main--title {
        color: #fff;
        font-family: "Pretendard700";
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .search--box {
        display: block;
        position: relative;
        input {
          border-radius: 16px;
          border: 1px solid var(--stroke-stroke, #232323);
          background: var(--section-bg, #141414);
          min-height: 40px;
          min-width: 240px;
          color: rgba(160, 174, 192, 0.5);
          font-family: Poppins400;
          font-size: 12px;
          font-style: normal;
          line-height: 150%; /* 18px */
          padding-left: 35px;
        }
        .search--btn {
          position: absolute;
          left: 10px;
          top: 12px;
          cursor: pointer;
        }
      } //search--box --END--
    } //header--box --END--
  }
  .wallet--list--box {
    border-radius: 16px;
    background: #141414;
    padding: 24px 40px 40px;
    width: 100%;
    overflow: auto;
  }
  table {
    min-width: 1300px;
    width: 100%;
    border-collapse: collapse;
    // margin: 25px 0;
    // font-size: 0.9em;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    thead {
      tr {
        background-color: #141414;
        color: #ffffff;
        th {
          color: #a0aec0;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          &:nth-child(1) {
            width: 100px;
          }
          &:nth-child(2) {
            width: 350px;
          }
          &:nth-child(3) {
            width: 65%;
            text-indent: -500px;
          }
        }
      }
    }
    tbody {
      tr {
        background-color: #141414;
        min-height: 34px;
        td {
          text-align: center;
          color: var(--text-white, #f4f4f4);
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          &:nth-child(3) {
            width: 65%;
            text-indent: -500px;
          }
          .cancel--btn {
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #bb2424;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #bb2424;
              text-shadow: 0 0 8px #0006;
            }
          }
          .link--btn {
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #235aad;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #235aad;
              text-shadow: 0 0 8px #0006;
            }
          }
          &:nth-child(7) {
            text-align: left;
          }
          &:nth-child(9) {
            width: 80px;
          }
          &:nth-child(10) {
            width: 80px;
          }
        }
        &:last-child {
          border-bottom: 0;
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  table th,
  table td {
    padding: 12px 8px;
    background-color: #141414;
    border-bottom: 1px solid var(--stroke-stroke, #232323);
  }

  .pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background: var(--button-grey, #272727);
      color: var(--text-white, #f4f4f4);
      text-align: center;
      font-family: Poppins500;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      margin-top: 30px;
      border: 1px solid #272727;
      transition: 0.3s;
      &:hover {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.active {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.prev,
      &.next {
        font-size: 0;
        background: url("../assets/images/arrow_next.png") #272727;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.prev {
        transform: rotate(180deg);
      }
    }
  }
}

@media (max-width: 650px) {
  .wallet {
    .wallet--list--box {
      padding: 10px 24px;
    }
  }
}

@media (max-width: 510px) {
  .wallet {
    .wallet--inner {
      padding: 40px 18px;
      .header--box {
        .main--title {
          font-size: 30px;
        }
      }
    }
  }
}
