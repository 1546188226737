










.loading--component{
  img{
    width: 30px;
  }
}




