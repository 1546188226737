body {
  background: #030303;
}
.user--detail {
  background: #030303;
  width: 100%;
  .user--detail--inner {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    display: flex;
    gap: 40px;
    .user--detail--box {
      width: 100%;
      .main--title {
        width: 100%;
        color: #fff;
        font-family: "Pretendard700";
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 16px;
      } //main--title -- END --
      .user--detail--list {
        border-radius: 16px;
        background: #141414;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .btn--box {
          width: 100%;
          display: flex;
          justify-content: right;
        }
        .user--btn {
          width: 80px;
          padding: 4px 8px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #d50f92;
          transition: 0.3s;
          &:hover {
            box-shadow: 0 0 10px #d50f92;
          }
        }
        .btn--reset {
          background-color: rgb(255, 0, 85);
          margin-right: 4px;
          &:hover {
            box-shadow: 0 0 10px rgb(255, 0, 85);
          }
        }
        dl {
          display: flex;
          gap: 16px;
          dt {
            width: 120px;
            color: #a0aec0;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
          }
          dd {
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            display: flex;
            align-items: center;
            span {
              font-family: "Pretendard500";
              font-size: 14px;
              display: flex;
            }
            p {
              color: #fff;
              text-align: left;
              font-family: "Pretendard500";
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              &:first-child {
                margin-bottom: 8px;
              }
              &::before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url("../assets/images/date_range.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                vertical-align: middle;
                margin-top: -4px;
                margin-right: 6px;
              }
              &.time {
                &::before {
                  background: url("../assets/images/schedule.png");
                }
              }
            }
            input {
              padding: 4px 8px;
              background-color: #272727;
              border-radius: 8px;
              font-size: 14px;
              color: #fff;
              color-scheme: dark;
            }
          }
          .block--input--box {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            flex-direction: column;
            &.checkbox {
              flex-direction: row;
              align-items: center;
            }
          }
        }
      } //user--detail--list -- END --
    } //user--detail--box -- END --
    .game--ids--box {
      width: 100%;
      .game--ids--title {
        width: 100%;
        color: #fff;
        font-family: "Pretendard700";
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        margin-bottom: 16px;
      }
      .game--ids--list {
        .list--item {
          border-radius: 4px;
          border: 1px solid var(--stroke-stroke, #232323);
          background: #141414;
          margin-bottom: 16px;
          padding: 16px;
          height: 48px;
          transition: 0.3s;
          overflow: hidden;
          &.active {
            height: 106px;
            dt {
              &::after {
                transform: translate(0, -50%) rotate(180deg);
              }
            }
            &.registered--id {
              dd {
                &::after {
                  opacity: 1;
                }
              }
            }
          }
          &.registered--id {
            background-color: #20821777;
            border: 1px solid #208217;
            dt {
              color: #fff;
              &::after {
                background: url("../assets/images/arrow_more_whithe.png");
              }
            }
            dd {
              color: #fff;
              display: flex;
              position: relative;
              display: flex;
              flex-direction: column;
              &::after {
                content: "";
                position: absolute;
                top: -8px;
                left: 0;
                width: 100%;
                height: 0.5px;
                background: #ffffff68;
                opacity: 0;
                transition: 0.3s;
              }
              p {
                display: flex;
                align-items: center;
                justify-self: center;
                button {
                  position: relative;
                  top: -2px;
                  left: -5px;
                }
              }
            }
          }
          dt {
            color: #fff;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              right: 0px;
              transform: translate(0, -50%);
              width: 16px;
              height: 16px;
              background: url("../assets/images/arrow_more_down.png");
              background-position: center;
              background-repeat: no-repeat;
              transition: 0.3s;
            }
          }
          dd {
            margin-top: 16px;
            color: #a0aec0;
            font-family: "Pretendard Variable";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .game--record--btn {
              position: absolute;
              right: 0;
              top: 4px;
              width: 100px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background: #235aad;
              color: #fff;
              text-align: center;
              font-family: "Pretendard500";
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              transition: 0.3s;
              &:hover {
                box-shadow: 0 0 10px #235aad;
              }
            }
          }
        }
      } //game--ids--list -- END --
    } // game--ids--box -- END --
    .header--box {
      display: flex;
      margin-bottom: 16px;
      gap: 40px;

      .search--box {
        display: block;
        position: relative;
        input {
          border-radius: 16px;
          border: 1px solid var(--stroke-stroke, #232323);
          background: var(--section-bg, #141414);
          min-height: 40px;
          min-width: 240px;
          color: rgba(160, 174, 192, 0.5);
          font-family: Poppins400;
          font-size: 12px;
          font-style: normal;
          line-height: 150%; /* 18px */
          padding-left: 35px;
        }
        .search--btn {
          position: absolute;
          left: 10px;
          top: 12px;
          cursor: pointer;
        }
      } //search--box --END--
    } //header--box --END--
  }
  .tournament--list--box {
    border-radius: 16px;
    background: #141414;
    padding: 24px 40px 40px;
    width: 100%;
    overflow: auto;
  }
  table {
    min-width: 1300px;
    width: 100%;
    border-collapse: collapse;
    // margin: 25px 0;
    // font-size: 0.9em;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    thead {
      tr {
        background-color: #141414;
        color: #ffffff;
        th {
          color: var(--text-grey, #6e6e6e);
          font-size: 12px;
          font-style: normal;
          line-height: normal;
        }
      }
    }
    tbody {
      tr {
        background-color: #141414;
        min-height: 34px;
        transition: 0.3s;
        // cursor: pointer;
        &:hover {
          // background: #262626;
        }
        td {
          text-align: center;
          color: var(--text-white, #f4f4f4);
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          .delete--btn {
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #bb2424;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #bb2424;
              text-shadow: 0 0 8px #0006;
            }
          }
          .detail--btn {
            width: 70px;
            height: 25px;
            color: #fff;
            text-align: center;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            border-radius: 4px;
            background: #235aad;
            transition: 0.3s;
            &:hover {
              box-shadow: 0 0 10px #235aad;
              text-shadow: 0 0 8px #0006;
            }
          }
          &:nth-child(7) {
            // text-align: left;
          }
          &:nth-child(8) {
            width: 80px;
            text-align: center;
            img {
              margin: 0 auto;
            }
          }
          &:nth-child(9) {
            width: 80px;
          }
          &:nth-child(10) {
            width: 80px;
          }
        }
        &:last-child {
          border-bottom: 0;
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  table th,
  table td {
    padding: 12px 8px;
    border-bottom: 1px solid var(--stroke-stroke, #232323);
  }

  .pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background: var(--button-grey, #272727);
      color: var(--text-white, #f4f4f4);
      text-align: center;
      font-family: Poppins500;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      margin-top: 30px;
      border: 1px solid #272727;
      transition: 0.3s;
      &:hover {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.active {
        border: 1px solid #d50f92;
        color: #d50f92;
      }
      &.prev,
      &.next {
        font-size: 0;
        background: url("../assets/images/arrow_next.png") #272727;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.prev {
        transform: rotate(180deg);
      }
    }
  }
}

@media (max-width: 880px) {
  .user--detail--inner {
    flex-direction: column;
  }
}

@media (max-width: 670px) {
  .user--detail {
    .user--detail--inner {
      .game--ids--box {
        .game--ids--title {
          font-size: 30px;
        }
      }
      .user--detail--box {
        .main--title {
          font-size: 30px;
        }
        .user--detail--list {
          .btn--box {
            .user--btn {
              width: 100%;
              padding: 8px;
            }
          }
          dl {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media (max-width: 510px) {
  .user--detail {
    .user--detail--inner {
      padding: 40px 18px;
      .user--detail--box {
        .user--detail--list {
          dl {
            flex-direction: column;
            &:nth-child(2) {
              dd {
                display: flex;
                flex-direction: column;
                overflow: hidden;
              }
            }
            dd {
              span {
                display: flex;
                text-align: left;
                width: 100%;
                word-break: break-all;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
