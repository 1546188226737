@font-face {
  font-family: "poppins300";
  src: url("./assets/fonts/Poppins300.ttf");
}
@font-face {
  font-family: "poppins400";
  src: url("./assets/fonts/Poppins400.ttf");
}
@font-face {
  font-family: "poppins500";
  src: url("./assets/fonts/Poppins500.ttf");
}
@font-face {
  font-family: "poppins600";
  src: url("./assets/fonts/Poppins600.ttf");
}
@font-face {
  font-family: "poppins700";
  src: url("./assets/fonts/Poppins700.ttf");
}

@font-face {
  font-family: "Syncopate700";
  src: url("./assets/fonts/Syncopate-Bold.ttf");
}
@font-face {
  font-family: "Poppins300";
  src: url("./assets/fonts/Poppins300.ttf");
}
@font-face {
  font-family: "Poppins400";
  src: url("./assets/fonts/Poppins400.ttf");
}
@font-face {
  font-family: "Poppins500";
  src: url("./assets/fonts/Poppins500.ttf");
}
@font-face {
  font-family: "Poppins600";
  src: url("./assets/fonts/Poppins600.ttf");
}
@font-face {
  font-family: "Poppins700";
  src: url("./assets/fonts/Poppins700.ttf");
}
@font-face {
  font-family: "Pretendard400";
  src: url("./assets/fonts/pretendard400.otf");
}
@font-face {
  font-family: "Pretendard500";
  src: url("./assets/fonts/pretendard500.otf");
}
@font-face {
  font-family: Pretendard400;
  src: url("./assets/fonts/pretendard400.otf");
}
@font-face {
  font-family: Pretendard500;
  src: url("./assets/fonts/pretendard500.otf");
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #030303;
  position: relative;
}
#root {
  background: #121212;
}
* {
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  font-family: "Poppins400";
  color: #fff;
  box-sizing: border-box;
  font-size: 16px;
}
*:focus {
  outline: 0;
  outline: none;
}
ul {
  list-style: none;
}

th {
  font-weight: normal;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
  background: transparent;
  color: #fff;
}

input {
  outline: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  appearance: none;
}
input[type="submit"] {
  cursor: pointer;
}
img {
  display: block;
}
.App {
  /* max-width: 1920px; */
  margin: 0 auto;
}
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background-color: #1f1f1f;
}
::-webkit-scrollbar-thumb {
  background: #353535;
  border-radius: 10px;
  border: 4px solid #1f1f1f;
  /* background-clip: padding-box; */
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  width: 5px;
}
::-webkit-scrollbar-button {
  display: none;
}
