.super-host-detail {
  padding: 40px;
  display: flex;
  width: 100%;
  gap: 40px;

  .super-host-detail__section {
    width: 100%;
  }
  .super-host-detail__section--title {
    font-family: "Pretendard500";
    font-size: 36px;
    margin-bottom: 16px;
  }
  .super-host-detail__section--box {
    padding: 24px;
    border-radius: 16px;
    background: #141414;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .super-host-detail__section--manager {
    display: flex;
    align-items: center;
  }
  .super-host-detail__section--manager-title {
    font-size: 14px;
    font-family: "Pretendard400";
    margin: 0;
    color: #a0aec0;
    width: 104px;
  }
  .super-host-detail__secrtion--manager-content {
    color: #fff;
    text-align: center;
    font-family: "Pretendard500";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    word-break: break-all;
  }

  .super-host-detail__section--manager-delelte {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
    border-top: 1px solid var(--stroke-stroke, #232323);
  }

  .super-host-detail__section--manager-delete-title {
    color: #fff;
    font-family: "Pretendard500";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  .super-host-detail__delete-btn {
    font-family: "Pretendard500";
    width: 80px;
    padding: 12px;
    border-radius: 4px;
    background: #bb2424;
    font-size: 14px;
    color: #fff;
  }

  .super-host-detail__section--game-description {
    border-bottom: 1px solid var(--stroke-stroke, #232323);
    padding: 12px 16px;
    font-family: "Pretendard500";
    font-size: 14px;
    font-style: normal;
    line-height: 100%; /* 14px */
  }

  .super-host-detail__section--game-description-number {
    font: inherit;
    display: inline-block;
    color: #6e6e6e;
    width: 40px;
    text-align: center;
  }

  .super-host-detail__section--game-description-name {
    color: #6e6e6e;
    font: inherit;
  }

  .super-host-detail__section--game-select {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 16px;
    width: 100%;
    img {
      cursor: pointer;
    }
  }
  .super-host-detail__section--game-content {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .game-index {
    min-width: 40px;
    text-align: center;
  }
  .game-name__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    color: #fff;
    font-family: "Pretendard500";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    flex: 1;
    position: relative;
    border-radius: 4px;
    border: 1px solid var(--stroke-stroke, #232323);
    background: var(--section-bg, #141414);
  }
  .game-name__box-dropdown {
    position: absolute;
    max-height: 100px;
    width: 102px;
    border-radius: 4px;
    border: 1px solid var(--stroke-stroke, #232323);
    background: #353535;
    left: 0;
    top: calc(100% + 8px);
    z-index: 2;
    width: 100%;
    padding: 8px;
    display: none;
    overflow: auto;
    font-size: 14px;
    &.active {
      display: block;
    }
  }
  .game-name__box--unselect {
    font: inherit;
    color: gray;
  }

  .game-name__box--dropdown-arrow {
    transform: rotate(0deg);
    width: 10px;
    height: 10px;
    transition: 0.3s;
    &.active-img {
      transform: rotate(180deg);
    }
  }

  .game-name__box-dropdown--content {
    padding: 8px;
    color: #fff;
    font-family: "Pretendard500";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    &:hover {
      border-radius: 4px 0px 0px 4px;
      background: #235aad;
    }
  }

  .super-host-detail__section--game-add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid var(--button-grey, #272727);
    color: var(--button-input, #cfcfcf);
    font-family: Poppins400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    img {
      height: 40px;
      object-fit: cover;
    }
  }
  .super-host-detail__section--permission {
    width: 100%;
    margin-top: 16px;
    padding: 12px;
    border-radius: 4px;
    background: #235aad;
    color: #fff;
    text-align: center;
    font-family: "Pretendard500";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }
}

@media screen and (max-width: 1024px) {
  .super-host-detail {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .super-host-detail {
    .super-host-detail__section--manager {
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }
  }
}
