.delete-modal__background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.Governance-cancel-modal__box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  z-index: 300;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #171717;
  /* bg_blur */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(15px);
  padding: 40px;
  .delete-modal__title {
    width: 320px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-family: "pretendard500";
    text-align: center;
    line-height: 22px;
  }

  .Governance-delete-modal__check-list {
    .checklist {
      list-style: none;
      padding: 0;
      margin: 0;
      .checklist-item {
        position: relative;
        margin-bottom: 15px;
        font-size: 14px;
  
        label {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 14px;
        }
  
        input[type='checkbox'] {
          display: none;
        }
  
        .custom-checkbox {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-color: #171717;
          border: 1px solid #ccc;
          margin-right: 15px;
          position: relative;
          transition: background-color 0.3s ease, border-color 0.3s ease;
  
          /* 원형 체크박스 디자인 */
          border-radius: 50%;
  
          &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 8px;
            height: 8px;
            background-color: white;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.2s ease;
            border-radius: 50%; /* 체크 마크 대신 원이 나타나도록 */
          }
        }
  
        /* 체크 상태일 때 스타일 */
        input[type='checkbox']:checked + .custom-checkbox {
          background-color: #4caf50;
          border-color: #4caf50;
  
          &:after {
            transform: translate(-50%, -50%) scale(1); /* 체크 마크 대신 원이 나타나도록 */
          }
        }
  
        input[type='checkbox']:checked + .custom-checkbox + span {
          text-decoration: line-through;
          color: #999;
        }
      }
    }
  }
  
  .delete-modal__btn-box {
    display: flex;
    margin-top: 24px;
    // .delete-modal__cancel-btn {
    //   width: 100%;
    //   height: 38px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   border-radius: 4px;
    //   color: #fff;
    //   font-size: 14px;
    //   font-family: "pretendard500";
    //   background-color: #272727;
    //   margin-right: 8px;
    // }
    .delete-modal__confirm-btn {
      color: #fff;
      width: 100%;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #bb2424;
      font-size: 14px;
      font-family: "pretendard500";
      transition: 0.3s;
      &:hover {
        box-shadow: 0 0 10px #bb2424;
        text-shadow: 0 0 8px #bb2424;
      }
    }
  }
}
