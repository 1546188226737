.header {
  position: relative;
  z-index: 3;
  width: 100%;
  // max-width: 1920px;
  height: 60px;
  background: #141414;
  border-bottom: 1px solid #232323;
  box-shadow: 0px 10px 8px 0px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  .header-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    height: 100%;

    h1 {
      display: flex;
      align-items: center;
      margin-right: 30px;
      a {
        color: #5ac4ff;
        // font-family: "worksans600";
        font-size: 29.448px;
        line-height: 100%;
      }

      img {
        width: 120px;
      }
    }
    .header-menu {
      width: 100%;
      height: 100%;
      text-align: left;
      .header-menu__ul {
        height: 100%;
        display: flex;
        gap: 16px;
        .header-menu__li {
          position: relative;
          a {
            position: relative;
            color: #fff;
            font-family: "Pretendard500";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            .gnb--detail {
              position: absolute;
              top: 59px;
              left: 0;
              width: 140px;
              height: 0;
              transition: 0.3s;
              overflow: hidden;
              &.active {
                // height: 120px;
                overflow: initial;
              }
              li {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                color: #fff;
                font-family: "Pretendard500";
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                background: #141414;
                transition: 0.3s;
                cursor: pointer;
                a {
                  padding: 12px;
                  color: #fff;
                  font-family: "Pretendard500";
                  font-size: 14px;
                  text-align: left;
                  display: block;
                  text-transform: uppercase;
                  &::after,
                  &::backdrop {
                    display: none;
                  }
                }
                &:hover {
                  background: #262626;
                }
              }
            }
            &:hover {
              .gnb--detail {
                height: auto;
              }
            }
          }
        }
        .header-menu__li-clicked {
          a {
            color: #d50f92;
            font-weight: bold;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: 0px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #d50f92;
              transition: 0.3s;
            }
            // color: #2f2f2f;
            // border-bottom: 2px solid #e60012;
          }
        }
      }
    }
    .header-menu__username {
      width: 195px;
      height: 100%;
      font-family: "pretendard500";
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user--info {
        p {
          color: #a0aec0;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
        }
        span {
          color: #f1f1f1;
          font-family: "Pretendard500";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .user--my--box {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        .profile--btn {
          background: url("../assets/images/plofileImg.png");
          width: 45px;
          height: 45px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .logout--btn {
          position: absolute;
          bottom: -40px;
          right: 0;
          z-index: 1;
          width: 140px;
          height: 40px;
          background-color: #141414;
          color: #fff;
          font-family: "Pretendard500";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: left;
          padding: 12px;
          transition: 0.3s;
          &:hover {
            background: #262626;
          }
        }
      } //user--my--box--END--
    }
    .m-main-menu {
      display: none;
    }
    .header__mobile-menu {
      display: none;
    }
  }
}
.m-main-menu__background {
  z-index: 0;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.4s;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
}
.active-menu {
  opacity: 1;
  z-index: 0;
  width: 100%;
  height: 100%;
  > .m-menu-list {
    width: 320px;
  }
}

@media screen and (max-width: 1000px) {
  .header {
    .header-inner {
      .header-menu {
        display: none;
      }
      .header-menu__username {
        display: none;
      }
      .m-main-menu {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 20px;
        z-index: 10;
        margin-top: 5px;
        cursor: pointer;
        span {
          position: absolute;
          width: 100%;
          height: 3px;
          border-radius: 3px;
          background-color: #2f2f2f;
          transition: 0.4s;
          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
          &:nth-child(3) {
            bottom: 0;
          }
        }
      }
      .active {
        span {
          &:nth-child(1) {
            transform: translateY(8px) rotate(-45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-8px) rotate(45deg);
          }
        }
      }
      .header__mobile-menu {
        display: block;
        width: 320px;
        height: 100%;
        overflow: auto;
        background-color: #121212;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        padding: 20px 0;
        .mobile-menu__login-status {
          font-size: 20px;
          margin-left: 10px;
          margin-top: 12px;
        }
        .mobile-menu__list {
          margin-top: 20px;
          .mobile-menu__li {
            border-bottom: 1px solid #2f2f2f;
            transition: 0.3s;
            &:hover {
              a {
                color: #fff;
              }
            }
            &:first-child {
              border-top: 1px solid #2f2f2f;
            }
            a {
              padding: 20px 0;
              display: block;
              width: 100%;
              height: 100%;
              padding-left: 20px;
              font-size: 14px;
            }
          }
        }
        .logout--btn {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: end;
          opacity: 0.7;
          padding: 20px 20px 0 0;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .header {
    // padding: 18.5px 16px;
    height: 60px;
    .header-inner {
      .header__mobile-menu {
        .mobile-menu__login-status {
          margin-top: 0;
        }
      }
      h1 {
        a {
          font-size: 20px;
        }
      }
    }
  }
}
